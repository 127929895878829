import styled from "styled-components";

export const MenuWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const MenuComponent = styled.div`
    display: inline-flex;
    flex-direction: row;
    width: 95%;
    padding: 1rem;
    align-items: center;
`

export const LogoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
`

export const CompanyName = styled.div`
    font-size: 1.3rem;
    padding: 0 1rem;
    color: #004A4D;
`

export const Logo = styled.img`
    background: transparent;
    width: 4rem;
    height: 4rem;
`

export const NavigationContentWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 .5rem;
`

export const NavigationElementWrapper = styled.span`
    padding: .5rem 2rem;
    border-radius: 5px;
    color: #004A4D;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background: #add7d8;
`

export const LanguageWrapper = styled.div<{width?: number}>`
    display: flex;
    justify-content: flex-end;
    ${({ width }) => width && `
        min-width: ${width}px;
        max-width: ${width}px;
    `}
`

export const LanguageComponent = styled.div`
    display: flex;
    align-items: center;
    padding: .5rem;
    justify-content: center;
    cursor: pointer;
`