import React, {useEffect, useRef, useState} from "react";
import {
    CompanyName, LanguageComponent, LanguageWrapper,
    Logo,
    LogoWrapper,
    MenuComponent,
    MenuWrapper,
    NavigationContentWrapper,
    NavigationElementWrapper
} from "./Menu.styles";
export const Menu = () => {
    const [ logoWidth, setLogoWidth ] = useState<number>(0);
    const logoRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(logoRef.current) {
            setLogoWidth(logoRef.current.offsetWidth);
        }
    }, [ logoRef ]);

    console.log("logo width: ", logoWidth);
    return (
        <MenuWrapper>
            <MenuComponent>
                <LogoWrapper ref={logoRef}>
                    <Logo src="/assets/img.png"/>
                    <CompanyName>mPacjent</CompanyName>
                </LogoWrapper>
                <NavigationContentWrapper>
                    <NavigationElementWrapper>
                        Support
                    </NavigationElementWrapper>
                </NavigationContentWrapper>
                <LanguageWrapper width={logoWidth}>
                    <LanguageComponent>
                        EN
                    </LanguageComponent>
                </LanguageWrapper>
            </MenuComponent>
        </MenuWrapper>
    )
}