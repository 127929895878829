import styled from "styled-components";

export const HomePageWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const MobileAppContentDescription = styled.div`
    width: 100%;
    padding: 2rem;
    display: flex;
`

export const LeftMobileContentDescription = styled.div`
    width: 50%;
    height: 40rem;
    position: relative;
    background: rgba(173, 215, 216, 0.28);
    box-sizing: border-box;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const RightMobileContentDescription = styled.div`
    width: 50%;
    height: 40rem;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const MobileContentTitle = styled.div`
    font-size: 3rem;
    font-weight: bold;
    color: #004A4D;
`

export const MobileContentDescription = styled.div`
    font-size: 1rem;
    color: #004A4D;
    padding: 1rem;
    text-align: center;
`

export const ImageBox = styled.div`
    padding: 5rem;
    position: relative;
`

export const MobileAppImage = styled.img<{top: string, left: string}>`
    height: 30rem;
    position: absolute;
    ${({ top }) => top && `top: ${top}`};
    ${({ left }) => left && `left: ${left}`};
`

export const HomeContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5rem 0;
    margin-top: 5rem;
    padding: 0 5rem;
`

export const DescriptionWrapper = styled.div<{flexPosition?: string}>`
    width: 100%;
    align-items: center;
    display: flex;
    ${({ flexPosition }) => flexPosition && `justify-content: ${flexPosition}`};
`

export const DescriptionBox = styled.div<{width?: string}>`
    ${({ width }) => width && `
        width: ${width || "100%"};
    `}
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: .5rem;
`

export const LeftBoxDescription = styled.div`
    width: 50%;
    height: 90%;
    position: relative;
    padding: 2rem 0;
    background: rgba(0, 74, 77, 0.04);
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    min-width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const DescriptionImage = styled.img`
    background: transparent;
    height: 15rem;
`

export const RightBoxDescription = styled.div`
    position: relative;
    padding: 2rem 0;
    flex-grow: 1;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    background: rgba(0, 74, 77, 0.04);
`

export const BoxCorner = styled.div<{bottomRight?: boolean, topRight?: boolean, bottomLeft?: boolean, topLeft?: boolean}>`
    position: absolute;
    ${({ bottomRight }) => (bottomRight && `
        bottom: 0;
        right: 0;
        border-bottom: 3px solid #004A4D;
        border-right: 3px solid #004A4D;
        width: 2rem;
        height: 2rem;
        border-bottom-right-radius: 10px;
    `)};
    ${({ topRight }) => (topRight && `
        top: 0;
        right: 0;
        border-top: 3px solid #004A4D;
        border-right: 3px solid #004A4D;
        width: 2rem;
        height: 2rem;
        border-top-right-radius: 10px;
    `)};
    ${({ topLeft }) => (topLeft && `
        top: 0;
        left: 0;
        border-top: 3px solid #004A4D;
        border-left: 3px solid #004A4D;
        width: 2rem;
        height: 2rem;
        border-top-left-radius: 10px;
    `)};
    ${({ bottomLeft }) => (bottomLeft && `
        left: 0;
        bottom: 0;
        border-bottom: 3px solid #004A4D;
        border-left: 3px solid #004A4D;
        width: 2rem;
        height: 2rem;
        border-bottom-left-radius: 10px;
    `)};

`