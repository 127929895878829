import React from "react";
import {SupportPageWrapper} from "./SupportPage.styles";

export const SupportPage = () => {

    return (
        <SupportPageWrapper>

        </SupportPageWrapper>
    )
}