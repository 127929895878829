import React from "react";
import {
    BoxCorner,
    DescriptionBox,
    DescriptionImage,
    DescriptionWrapper,
    HomeContentWrapper,
    HomePageWrapper,
    ImageBox,
    LeftBoxDescription,
    LeftMobileContentDescription,
    MobileAppContentDescription,
    MobileAppImage, MobileContentDescription, MobileContentTitle,
    RightBoxDescription,
    RightMobileContentDescription
} from "./HomePage.styles";
import {Menu} from "../../components/Menu/Menu";

export const HomePage = () => {

    return (
        <HomePageWrapper>
            <Menu/>
            <MobileAppContentDescription>
                <LeftMobileContentDescription>
                    <MobileAppImage src="assets/phone/appointmentList.png" top="10%" left="20%"/>
                    <MobileAppImage src="assets/phone/appointmentPage.png" top="15%" left="35%"/>
                    <MobileAppImage src="assets/phone/homePage.png" top="20%" left="50%"/>
                </LeftMobileContentDescription>
                <RightMobileContentDescription>
                    <MobileContentTitle>mPacjent</MobileContentTitle>
                    <MobileContentDescription>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged
                    </MobileContentDescription>
                </RightMobileContentDescription>
            </MobileAppContentDescription>
            <HomeContentWrapper>
                <DescriptionWrapper flexPosition="flex-end">
                    <DescriptionBox width="60%">
                        <BoxCorner bottomLeft={true}/>
                        <BoxCorner topLeft={true}/>
                        <BoxCorner bottomRight={true}/>
                        <BoxCorner topRight={true}/>
                        <LeftBoxDescription>
                            <DescriptionImage src="/assets/illustrations/mobileContent.svg"/>
                        </LeftBoxDescription>
                        <RightBoxDescription>

                        </RightBoxDescription>
                    </DescriptionBox>
                </DescriptionWrapper>
                <DescriptionWrapper flexPosition="flex-start">
                    <DescriptionBox width="60%">
                        <BoxCorner bottomLeft={true}/>
                        <BoxCorner topLeft={true}/>
                        <BoxCorner bottomRight={true}/>
                        <BoxCorner topRight={true}/>
                        <LeftBoxDescription>
                            <DescriptionImage src="/assets/illustrations/notifications.svg"/>
                        </LeftBoxDescription>
                        <RightBoxDescription>

                        </RightBoxDescription>
                    </DescriptionBox>
                </DescriptionWrapper>
                <DescriptionWrapper flexPosition="flex-end">
                    <DescriptionBox width="60%">
                        <BoxCorner bottomLeft={true}/>
                        <BoxCorner topLeft={true}/>
                        <BoxCorner bottomRight={true}/>
                        <BoxCorner topRight={true}/>
                        <LeftBoxDescription>
                            <DescriptionImage src="/assets/illustrations/completed.svg"/>
                        </LeftBoxDescription>
                        <RightBoxDescription>

                        </RightBoxDescription>
                    </DescriptionBox>
                </DescriptionWrapper>
                <DescriptionWrapper flexPosition="flex-start">
                    <DescriptionBox width="60%">
                        <BoxCorner bottomLeft={true}/>
                        <BoxCorner topLeft={true}/>
                        <BoxCorner bottomRight={true}/>
                        <BoxCorner topRight={true}/>
                        <LeftBoxDescription>
                            <DescriptionImage src="/assets/illustrations/calendar.svg"/>
                        </LeftBoxDescription>
                        <RightBoxDescription>

                        </RightBoxDescription>
                    </DescriptionBox>
                </DescriptionWrapper>
            </HomeContentWrapper>
        </HomePageWrapper>
    )
}