import React from 'react';
import './App.css';
import {AppWrapper} from "./App.styles";
import {HomePage} from "./views/HomePage/HomePage";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {SupportPage} from "./views/SupportPage/SupportPage";

function App() {
    const redirectToHome = () => <Navigate to="/home"/>;
    return (
        <AppWrapper>
            <BrowserRouter>
                <Routes>
                    <Route path="/home" element={<HomePage/>}/>
                    <Route path="/support" element={<SupportPage/>}/>
                    <Route path="*" element={redirectToHome()}/>
                </Routes>
            </BrowserRouter>
        </AppWrapper>
    );
}

export default App;
